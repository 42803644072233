import { useAddItemToCart } from 'hooks/cart';
import { useCallback, useEffect, useState } from 'react';
import {
  AddToCartCustomOption,
  Cart,
  CustomizableOptionValueInterface,
  ProductInterface,
  ProductTypeEnum,
} from 'types/types';
import resourceUrl from '../../../../util/make-url';
import {
  mapProductToPayload,
  trackCartEvent,
} from '../../../../util/tag-manager';
import { isLimitedTimeDeal } from '../../../../util/products';
import { useAddedToCartNotification } from 'hooks/product';
import AddedToCartNotificationBody from 'components/_shared/widgets/product/added-to-cart-notification-body';
import { EventNamesEnum } from 'types/third-party/gtm';
import { useCustomer } from 'hooks/customer';

export const useQuickAddToCart = ({
  product,
  onComplete,
}: {
  product: ProductInterface;
  onComplete: () => void;
}) => {
  const addedToCartNotification = useAddedToCartNotification();
  const user = useCustomer();

  const [quantityAdded, setQuantityAdded] = useState(1);

  const [selectedOption, setSelectedOption] = useState<
    CustomizableOptionValueInterface | undefined
  >(undefined);

  const displayPrice = selectedOption?.price.fixed || product.price;
  const notificationImageUrl = resourceUrl(
    selectedOption?.image?.url || product.image.url,
    { width: 40 }
  );

  const onAddToCartComplete = useCallback(
    ({ message }: { cart: Cart; message?: string }) => {
      addedToCartNotification({
        product,
        withContinueShoppingAction: false,
        body: (
          <AddedToCartNotificationBody
            imageUrl={notificationImageUrl}
            quantity={quantityAdded}
            price={displayPrice}
            message={message}
          />
        ),
      });

      onComplete();

      trackCartEvent({
        product: mapProductToPayload(product),
        event: EventNamesEnum.addToCart,
        productQuantity: quantityAdded,
        user,
      });
      trackCartEvent({
        product: mapProductToPayload(product),
        event: EventNamesEnum.quickAddToCartAdded,
        productQuantity: quantityAdded,
        user,
      });
    },
    [
      addedToCartNotification,
      displayPrice,
      notificationImageUrl,
      onComplete,
      product,
      quantityAdded,
      user,
    ]
  );

  const [addToCart, { loading, error }] = useAddItemToCart({
    onComplete: onAddToCartComplete,
    disableErrorToast: true,
  });

  const addToCartCallback = useCallback(
    ({
      quantity = 1,
      customOptions,
    }: {
      quantity?: number;
      customOptions?: AddToCartCustomOption[];
    } = {}) => {
      addToCart({
        type: ProductTypeEnum.Simple,
        sku: product.sku,
        quantity,
        customOptions,
      });
      setQuantityAdded(quantity);
    },
    [product, addToCart]
  );

  const trackOpen = useCallback(() => {
    trackCartEvent({
      product: mapProductToPayload(product),
      event: isLimitedTimeDeal(product)
        ? EventNamesEnum.quickAddToCartLTDClick
        : EventNamesEnum.quickAddToCartClick,
      user,
    });
  }, [product, user]);

  const trackClose = useCallback(() => {
    trackCartEvent({
      product: mapProductToPayload(product),
      event: EventNamesEnum.quickAddToCartOptionsClosed,
      user,
    });
  }, [product, user]);

  return {
    addToCartCallback,
    setSelectedOption,
    loading,
    trackOpen,
    trackClose,
    error,
  };
};
