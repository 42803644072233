import { css } from '@emotion/react';
import Button, { ButtonProps } from 'components/button';
import { useCallback, useState } from 'react';
import { ProductInterface } from 'types/types';
import Icon from './icon';
import { isCustomizable } from 'types/guards/product';
import Loading from 'components/loading';
import dynamic from 'next/dynamic';
import { useQuickAddToCart } from './hooks';

const ProductDetails = dynamic(() => import('./product-details'));
const Modal = dynamic(
  () => import('components/_shared/widgets/modal/with-close')
);

type Props = ButtonProps & {
  product: ProductInterface;
  iconSize?: number[];
  background?: string;
  openDetails?: () => void;
  /**
   * this is the worst possible property name,
   * but this is literally all it's for in the upsell feed,
   * and I don't have time to find a way to achieve the same effect
   * without some weird margin top and without messing up the homepage.
   */
  alignWithPricing?: boolean;
};

const LOADING_ICON_BORDER_WIDTH = 2;

const QuickAddToCart = ({
  product,
  iconSize = [24, 24],
  background,
  openDetails,
  alignWithPricing,
  ...restProps
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const completeCallback = useCallback(() => setIsModalOpen(false), []);

  const {
    addToCartCallback,
    setSelectedOption,
    loading,
    trackOpen,
    trackClose,
    error,
  } = useQuickAddToCart({ product, onComplete: completeCallback });

  const onButtonClick = useCallback(() => {
    trackOpen();

    if (isCustomizable(product) || product.externalListingLink) {
      if (openDetails) {
        openDetails();
      } else {
        setIsModalOpen(true);
      }
    } else {
      addToCartCallback();
    }
  }, [product, addToCartCallback, trackOpen, openDetails]);

  const buttonSize = iconSize.map(s => (s += LOADING_ICON_BORDER_WIDTH * 4));

  return (
    <>
      <Button
        variant="flat"
        title="Add to cart"
        onClick={onButtonClick}
        disabled={loading || product.isSoldOut}
        className={loading ? 'loading' : undefined}
        borderRadius="50%"
        p={1}
        mt={alignWithPricing ? 2 : undefined}
        width={buttonSize}
        height={buttonSize}
        {...restProps}
        css={theme => css`
          z-index: 100;
          position: relative;

          border: none;
          outline: revert;

          background: ${background
            ? theme.colors[background]
            : theme.colors.lightGrey};
          transition: background 0.2s ease;

          & .icon {
            stroke: ${theme.colors.black};
            transition: stroke 0.2s ease;
          }

          &:hover {
            background: ${theme.colors.darkestGrey};

            .icon {
              stroke: ${theme.colors.white};
            }
          }

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            opacity: 1;
            border: ${LOADING_ICON_BORDER_WIDTH}px solid transparent;
            border-left-color: transparent;
            border-radius: 100%;
            box-sizing: border-box;
            top: 50%;
            left: 50%;

            width: ${`calc(100% + ${LOADING_ICON_BORDER_WIDTH * 4}px)`};
            height: ${`calc(100% + ${LOADING_ICON_BORDER_WIDTH * 4}px)`};
            margin-top: ${`calc(-50% - ${LOADING_ICON_BORDER_WIDTH * 2}px)`};
            margin-left: ${`calc(-50% - ${LOADING_ICON_BORDER_WIDTH * 2}px)`};

            @keyframes loading {
              0% {
                transform: rotate(0deg) scale(1);
              }
              50% {
                transform: rotate(180deg) scale(1);
              }
              100% {
                transform: rotate(360deg) scale(1);
              }
            }
          }

          &.loading::before {
            animation: loading 1s linear infinite;
            border-color: ${theme.colors.primary};
            border-left-color: transparent;
          }
        `}
      >
        <Icon className="icon" size={iconSize} />
      </Button>

      {(isCustomizable(product) || product.externalListingLink) &&
        !openDetails && (
          <Modal
            isOpen={isModalOpen}
            close={() => {
              setIsModalOpen(false);
              trackClose();
            }}
            size="medium"
            iconSize={24}
            iconColor="black"
            padding={['12px', null, 2]}
            width="90%"
            maxWidth={product?.gallery ? '950px' : '700px'}
            borderRadius="10px"
            shouldPortal
            fixToBottomOnMobileAndTablet
          >
            {isModalOpen ? (
              <ProductDetails
                product={product}
                addToCart={addToCartCallback}
                loading={loading}
                setSelectedOption={setSelectedOption}
                addToCartError={error}
              />
            ) : (
              <Loading isLoading>
                <div
                  css={css`
                    height: 200px;
                  `}
                />
              </Loading>
            )}
          </Modal>
        )}
    </>
  );
};

export default QuickAddToCart;
