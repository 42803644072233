import { FunctionComponent } from 'react';
import Pill from './component';
import { css } from '@emotion/react';
import { PillVariantsEnum } from 'types/types';

export type IconReadyProps = {
  icon?: FunctionComponent;
  label: string;
  variant?: PillVariantsEnum;
  ml?: number;
  mb?: number;
};

const IconReady = ({
  icon: Icon,
  label,
  variant = PillVariantsEnum.muted,
  ml = 2,
  mb = 0,
}: IconReadyProps) => (
  <Pill variant={variant} ml={ml} mb={mb} className="pill">
    {Icon && (
      <Icon
        css={css`
          height: 12px;
          float: left;
          padding-right: 0.5em;
        `}
      />
    )}
    {label}
  </Pill>
);

export default IconReady;
