import { css } from '@emotion/react';
import { Box, Flex, Grid } from 'components/box';
import { Heading, LinkProps } from 'components/typography';
import { useIsTimedOut } from 'hooks/product';
import { Link, useRouterPush } from 'components/configurable-routing';
import {
  memo,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { PillVariantsEnum, ProductInterface } from 'types/types';
import { fadeAnimations, soldOutWrapperStyle } from 'shared/style';
import { productHoverStyle, ItemSizeEnum, Sizes } from './shared';
import Pill from 'components/_shared/elements/pill';
import SoldOutMask from 'components/sold-out-mask';
import SavingsBadge from 'components/savings-badge';
import ResponsiveImage, {
  mediaBreakpoints,
} from 'components/_shared/elements/responsive-image';
import {
  mapProductToPayload,
  trackProductClickEvent,
} from '../../../../util/tag-manager';
import IconReady, {
  IconReadyProps,
} from 'components/_shared/elements/pill/icon-ready';
import { setScrollPosition as setScrollPositionAction } from '../../../../redux/customer/actions';
import { useDispatch } from 'react-redux';
import mem from 'mem';
import { dedupe } from '../../../../util/arrays';
import { RowSize } from '.';
import QuickAddToCart from 'components/_shared/widgets/quick-add-to-cart';
import { useResized } from 'hooks/display';

const MAX_PILLS = 2;

const ProductPills = ({ product }: { product: ProductInterface }) => {
  const {
    lockdownText,
    blueTag,
    yellowTag,
    xLeftQuantity,
    callout,
    isBestSeller,
  } = product;

  const pills: (IconReadyProps & { key: string })[] = [
    // xLeft
    ...(xLeftQuantity
      ? [
          {
            key: 'xLeft',
            variant: PillVariantsEnum.warning,
            label: `${xLeftQuantity} left`,
          },
        ]
      : []),
    // best seller
    ...(isBestSeller
      ? [
          {
            key: 'bestSeller',
            variant: PillVariantsEnum.red,
            label: 'Best Seller',
          },
        ]
      : []),
    // callout
    ...(callout && !xLeftQuantity
      ? [
          {
            key: 'callout',
            variant: PillVariantsEnum.grey,
            label: callout,
          },
        ]
      : []),
    // lockdownText
    ...(lockdownText && !xLeftQuantity
      ? [
          {
            key: 'lockdownText',
            variant: PillVariantsEnum.muted,
            label: lockdownText,
          },
        ]
      : []),
    // blueTag
    ...(blueTag && !xLeftQuantity
      ? [
          {
            key: 'blueTag',
            variant: PillVariantsEnum.info,
            label: blueTag,
          },
        ]
      : []),
    // yellowTag
    ...(yellowTag && !xLeftQuantity
      ? [
          {
            key: 'yellowTag',
            variant: PillVariantsEnum.info,
            label: yellowTag,
          },
        ]
      : []),
  ].slice(0, MAX_PILLS);

  if (pills.length === 0) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      css={css`
        position: absolute;
        bottom: 10px;
        right: 10px;
        & .pill {
          margin-top: 4px;
          margin-left: 3px;
        }
      `}
    >
      {pills.map(({ key, ...props }) => (
        <IconReady key={key} {...props} />
      ))}
    </Flex>
  );
};

const wrapperFontSizes = {
  [ItemSizeEnum.extraSmall]: 's',
  [ItemSizeEnum.small]: 'r',
  [ItemSizeEnum.medium]: 'r',
  [ItemSizeEnum.large]: '1.4rem',
  [ItemSizeEnum.extraLarge]: '1.4rem',
};

const priceFontSizes = {
  [ItemSizeEnum.extraSmall]: ['s', 'r', 'm'],
  [ItemSizeEnum.small]: ['s', 'm', 'l'],
  [ItemSizeEnum.medium]: ['s', 'm', 'l'],
  [ItemSizeEnum.large]: ['m', 'xl', 'xl'],
  [ItemSizeEnum.extraLarge]: ['xl', 'xl', 'xl'],
};

const retailPriceFontSizes = {
  [ItemSizeEnum.extraSmall]: ['xs', 's', 's'],
  [ItemSizeEnum.small]: ['s', 'r', 'r'],
  [ItemSizeEnum.medium]: ['s', 'r', 'r'],
  [ItemSizeEnum.large]: ['s', 'm', 'm'],
  [ItemSizeEnum.extraLarge]: ['r', 'm', 'm'],
};

const soldOutMaskFontSizes = {
  [ItemSizeEnum.extraSmall]: ['s', 'r', 'm'],
  [ItemSizeEnum.small]: ['r', 'm', 'xl'],
  [ItemSizeEnum.medium]: ['m', 'l', 'x2l'],
  [ItemSizeEnum.large]: ['l', 'xl', 'x3l'],
  [ItemSizeEnum.extraLarge]: ['l', 'xl', 'x3l'],
};

const savingsBadgeLargeSizes = {
  [ItemSizeEnum.extraSmall]: [false, false, false],
  [ItemSizeEnum.small]: [false, false, false],
  [ItemSizeEnum.medium]: [false, false, false],
  [ItemSizeEnum.large]: [false, true, true],
  [ItemSizeEnum.extraLarge]: [true, true, true],
};

const imageSizesMobile = {
  [ItemSizeEnum.extraSmall]: [100, 200],
  [ItemSizeEnum.small]: [100, 200],
  [ItemSizeEnum.medium]: [100, 200],
  [ItemSizeEnum.large]: [200, 400],
  [ItemSizeEnum.extraLarge]: [400, 800],
};

const imageSizesTablet = {
  [ItemSizeEnum.extraSmall]: [100, 200],
  [ItemSizeEnum.small]: [200, 400],
  [ItemSizeEnum.medium]: [300, 600],
  [ItemSizeEnum.large]: [400, 800],
  [ItemSizeEnum.extraLarge]: [800, 1200],
};

const imageSizesDesktop = {
  [ItemSizeEnum.extraSmall]: 200,
  [ItemSizeEnum.small]: 300,
  [ItemSizeEnum.medium]: 400,
  [ItemSizeEnum.large]: 600,
  [ItemSizeEnum.extraLarge]: 1200,
};

export interface ProductSizes {
  imageWidths: number[];
  imageSizes: string[];
  wrapperFontSize: string[];
  priceFontSize: string[];
  retailPriceFontSize: string[];
  soldOutMaskFontSize: string[];
  isSavingsBadgeLarge: boolean[];
  screenSizes: Sizes;
}

const prepImageWidths = (sizes: Sizes) => {
  let widths: number[] = [];

  if (sizes[0]) {
    widths = [...widths, ...imageSizesMobile[sizes[0]]];
  }
  if (sizes[1]) {
    widths = [...widths, ...imageSizesTablet[sizes[1]]];
  }
  if (sizes[2]) {
    widths = [...widths, imageSizesDesktop[sizes[2]]];
  }

  // we don't want any duplicate sizes and we might as well put them in ascending order
  return dedupe(widths).sort((first, second) => first - second);
};

const prepImageSizes = (sizes: Sizes, rowSizes: RowSize[]) => {
  // we use media queries and viewport width allocation for mobile and tablet which have constrained widths
  // for desktop however we just want to use the size in pixels of the space the image is likely to fill
  return [
    `${mediaBreakpoints[0]} ${100 / rowSizes[0]}vw`,
    `${mediaBreakpoints[1]} ${100 / rowSizes[1]}vw`,
    `${imageSizesDesktop[sizes[2]]}px`,
  ];
};

export const prepProductSizes = mem(
  (sizes: Sizes, rowSizes: RowSize[]) => {
    const imageWidths = prepImageWidths(sizes);
    const imageSizes = prepImageSizes(sizes, rowSizes);

    return sizes.reduce(
      (agg, size, idx) => {
        const {
          wrapperFontSize,
          priceFontSize,
          retailPriceFontSize,
          soldOutMaskFontSize,
          isSavingsBadgeLarge,
          ...rest
        } = agg;

        return {
          wrapperFontSize: [...wrapperFontSize, wrapperFontSizes[size]],
          priceFontSize: [...priceFontSize, priceFontSizes[size][idx]],
          retailPriceFontSize: [
            ...retailPriceFontSize,
            retailPriceFontSizes[size][idx],
          ],
          soldOutMaskFontSize: [
            ...soldOutMaskFontSize,
            soldOutMaskFontSizes[size][idx],
          ],
          isSavingsBadgeLarge: [
            ...isSavingsBadgeLarge,
            savingsBadgeLargeSizes[size][idx],
          ],
          ...rest,
        };
      },
      {
        imageWidths,
        imageSizes,
        wrapperFontSize: [] as string[],
        priceFontSize: [] as string[],
        retailPriceFontSize: [] as string[],
        soldOutMaskFontSize: [] as string[],
        isSavingsBadgeLarge: [] as boolean[],
        screenSizes: sizes,
      }
    );
  },
  { cacheKey: ([sizes]) => JSON.stringify(sizes) }
);

const defaultSizes: ProductSizes = prepProductSizes(
  [ItemSizeEnum.medium],
  [2, 3, 3]
);

interface GalleryProductProps {
  product: ProductInterface;
  sizes?: ProductSizes;
  hideDetails?: boolean;
  isAccented?: boolean[];
  isLimitedTimeDeal?: boolean;
  noLink?: boolean;
  preloadImage?: boolean;
  disableActions?: boolean;
  hidePills?: boolean;
  ellipsisBrand?: boolean;
  smallSavingsBadge?: boolean;
  openQuickAddToCart?: () => void;
}

const GalleryProductSkeletonImage = ({ id }: { id: string }) => (
  <div
    id={id}
    css={theme => css`
      background-color: ${theme.colors.skeletonBackground};
      border-radius: ${theme.radii[3]}px;
      margin-bottom: 10px;

      @media ${theme.mediaQueries.mobileOnly} {
        margin-bottom: 11px;
      }

      /* square */
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `}
  />
);

const GalleryProductSkeleton = memo(
  ({
    sizes = defaultSizes,
    hideDetails,
    noLink,
    height,
    product,
  }: GalleryProductProps & {
    height: number;
  }) => (
    <Box
      css={
        height !== 0 &&
        css`
          height: ${height}px;
          overflow-y: hidden;
        `
      }
    >
      {noLink && (
        <GalleryProductSkeletonImage id={product.shop?.id || product.id} />
      )}

      {!noLink && (
        <GalleryProductLink
          product={product}
          setScrollPosition
          css={css`
            backface-visibility: hidden;
            ${fadeAnimations};
          `}
        >
          <GalleryProductSkeletonImage id={product.shop?.id || product.id} />
        </GalleryProductLink>
      )}

      {!hideDetails && (
        <>
          <Grid
            gridTemplateRows="auto 1fr"
            gridColumnGap={0}
            gridRowGap={0}
            mt={3}
            css={css`
              overflow: hidden;
            `}
          >
            <Heading
              fontSize={['0.9rem', '1.1rem', 2]}
              fontWeight="bold"
              lineHeight="1.2em"
              color="black"
              css={css`
                word-break: break-word;
              `}
            >
              {product.brand}
            </Heading>
            <Heading
              fontWeight={500}
              fontSize={['0.8rem', '1.1rem', 2]}
              color="darkGrey"
              lineHeight="1.5em"
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              `}
            >
              {product.shortName || product.name}
            </Heading>
          </Grid>

          {/* pricing placeholder */}
          <div
            css={theme => css`
              background-color: ${theme.colors.skeletonBackground};
              margin-bottom: ${theme.space[2]}px;
              margin-top: ${theme.space[2]}px;
              border-radius: ${theme.radii[3]}px;

              max-width: 100px;

              height: 30px;
              ${sizes &&
              css`
                @media ${theme.mediaQueries.mobileOnly} {
                  ${sizes.screenSizes[0] === ItemSizeEnum.large
                    ? css`
                        height: 21px;
                      `
                    : css`
                        height: 31px;
                      `}
                }

                @media ${theme.mediaQueries.tabletOnly} {
                  ${sizes.screenSizes[1] ===
                  (ItemSizeEnum.small || ItemSizeEnum.medium)
                    ? css`
                        height: 18px;
                      `
                    : css`
                        height: 27px;
                      `}
                }
              `}
            `}
          />
        </>
      )}
    </Box>
  )
);

const GalleryProductTitles = ({
  product,
  ellipsisBrand,
}: {
  product: ProductInterface;
  ellipsisBrand?: boolean;
}) => (
  <Grid
    role="group"
    gridTemplateRows="auto 1fr"
    gridColumnGap={0}
    gridRowGap={0}
    mt={3}
    overflow="hidden"
  >
    <Heading
      fontSize={['0.9rem', '1.1rem', 2]}
      fontWeight="bold"
      lineHeight="1.2em"
      color="black"
      css={css`
        word-break: break-word;
        ${ellipsisBrand &&
        css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      `}
    >
      {product.brand}
    </Heading>
    <Heading
      fontWeight={500}
      fontSize={['0.8rem', '1.1rem', 2]}
      color="darkGrey"
      lineHeight="1.5em"
      css={css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
    >
      {product.shortName || product.name}
    </Heading>
  </Grid>
);

const GalleryProductPricing = ({
  product,
  sizes = defaultSizes,
}: {
  product: ProductInterface;
  sizes?: ProductSizes;
}) => (
  <Flex alignItems="flex-end" flexWrap="wrap" role="group" overflow="hidden">
    {product.hasPriceRange && (
      <Heading
        fontSize={[1, 2]}
        color="black"
        className="highlightOnHover"
        fontWeight={700}
        mr={1}
        mt={2}
      >
        From
      </Heading>
    )}

    <Heading
      tabIndex={0}
      aria-label={
        product.hasPriceRange
          ? product.minPrice.formattedValue
          : product.price.formattedValue
      }
      fontSize={sizes.priceFontSize}
      fontWeight={600}
      mr={2}
      mt={2}
      color="black"
      className="highlightOnHover"
      css={css`
        position: relative;
        bottom: -0.05em;
      `}
    >
      {product.hasPriceRange
        ? product.minPrice.formattedValue
        : product.price.formattedValue}
    </Heading>

    {product.retailPrice.value > 0 && (
      <Heading
        tabIndex={0}
        aria-label={product.retailPrice.formattedValue}
        fontSize={sizes.retailPriceFontSize}
        color="darkGrey"
        fontWeight={500}
        mt={2}
        css={css`
          text-decoration: line-through;
        `}
      >
        {product.retailPrice.formattedValue}
      </Heading>
    )}
  </Flex>
);

const GalleryProductActions = ({
  product,
  openQuickAddToCart,
}: {
  product: ProductInterface;
  openQuickAddToCart?: () => void;
}) => (
  <Box my="auto">
    <QuickAddToCart
      product={product}
      background={product.shop?.id ? 'white' : undefined}
      openDetails={openQuickAddToCart}
      alignWithPricing={!!openQuickAddToCart}
    />
  </Box>
);

const GalleryProductClearancePills = ({
  product,
}: {
  product: ProductInterface;
}) => (
  <>
    {product.isClearanceSale &&
      (product.lockdownText ||
        product.blueTag ||
        product.yellowTag ||
        product.callout) && (
        <Box mr={-2} mt={[-2, 0]}>
          {product.callout && (
            <Pill variant={PillVariantsEnum.grey} mr={2} mt={0} mb={1}>
              {product.callout}
            </Pill>
          )}

          {product.lockdownText && (
            <Pill variant={PillVariantsEnum.grey} mr={2} mt={0} mb={1}>
              {product.lockdownText}
            </Pill>
          )}

          {product.blueTag && (
            <Pill variant={PillVariantsEnum.grey} mr={2} mt={0} mb={1}>
              {product.blueTag}
            </Pill>
          )}

          {product.yellowTag && (
            <Pill variant={PillVariantsEnum.grey} mr={2} mt={0} mb={1}>
              {product.yellowTag}
            </Pill>
          )}
        </Box>
      )}
  </>
);

const GalleryProductLink = ({
  product,
  children,
  date,
  setScrollPosition,
  ...restProps
}: {
  product: ProductInterface;
  date?: string | string[];
  setScrollPosition?: boolean;
  children: ReactElement | ReactElement[];
} & LinkProps) => {
  const dispatch = useDispatch();
  const push = useRouterPush();

  const scrollPosId = product.shop?.id || product.id;

  return (
    <Link
      title={`${product.brand}, ${product.name}`}
      {...restProps}
      dynamicUrl="/products/[id]"
      href={`/products/${product.id}${date ? `/?when=${date}` : ''}`}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        setScrollPosition && dispatch(setScrollPositionAction(scrollPosId));

        trackProductClickEvent({
          product: mapProductToPayload(product),
          callback: () => {
            if (!e.ctrlKey && !e.metaKey) {
              push({
                url: `/products/${product.id}${date ? `/?when=${date}` : ''}`,
              });
            }
          },
          yieldCallbackToGTM: true,
        });
      }}
    >
      {children}
    </Link>
  );
};

const GalleryProductImage = memo(
  ({
    product,
    sizes = defaultSizes,
    isAccented = [false, false, false],
    isLimitedTimeDeal,
    preloadImage,
    hidePills,
    smallSavingsBadge,
  }: GalleryProductProps) => {
    const isTimedOut = useIsTimedOut(
      product?.campaign ? product.activeToDate || '' : ''
    );

    const hideBadge = !product.saving || product.retailPrice.value <= 0;

    return (
      <Flex
        bg="white"
        className="image-wrapper"
        alignItems="flex-start"
        css={theme => css`
          border-radius: ${theme.radii[3]}px;
          position: relative;
          min-width: 100%;
          overflow: hidden;
          transition: all 0.2s ease;
          will-change: transform;

          &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }

          ${!isLimitedTimeDeal && productHoverStyle(theme)};

          ${isLimitedTimeDeal &&
          css`
            height: 100%;
            border: none;
            border-radius: ${theme.radii[3]}px;
            &:hover {
              box-shadow: none;
            }
          `}
        `}
      >
        <>
          {product.isSoldOut && (
            <Box className="soldOutWrapper">
              <SoldOutMask
                text
                isDark
                clock={isLimitedTimeDeal}
                headingFontSize={sizes.soldOutMaskFontSize}
                isPromoDeal={product.isPromotionalDeal}
                price={product.price.formattedValue}
              />
            </Box>
          )}

          {isTimedOut && (
            <Box className="soldOutWrapper">
              <SoldOutMask
                clock
                isDark
                headingFontSize={sizes.soldOutMaskFontSize}
              />
            </Box>
          )}

          <ResponsiveImage
            title={product.name}
            url={product.image.url}
            widths={sizes.imageWidths}
            sizes={sizes.imageSizes}
            preload={preloadImage}
          />

          {!hideBadge && (
            <SavingsBadge
              borderRadiusBottomRight={[false, false, true]}
              borderRadiusBottomLeft={[true, true, true]}
              borderRadiusTopLeft={[true, true, false]}
              isAccented={isAccented}
              className="savings-badge"
              isLarge={sizes.isSavingsBadgeLarge}
              saving={product.saving}
              alwaysSmall={smallSavingsBadge}
            />
          )}

          {product.isClearanceSale && product.isBestSeller && (
            <Box
              css={css`
                position: absolute;
                bottom: 10px;
                left: 10px;
              `}
            >
              <IconReady
                variant={PillVariantsEnum.warning}
                label="Best Seller"
              />
            </Box>
          )}

          {!product.isClearanceSale && !hidePills && (
            <ProductPills product={product} />
          )}
        </>
      </Flex>
    );
  }
);

const GalleryProduct = memo(
  ({
    product,
    sizes = defaultSizes,
    hideDetails,
    noLink,
    isLimitedTimeDeal,
    disableActions,
    ellipsisBrand,
    openQuickAddToCart,
    ...restProps
  }: GalleryProductProps) => {
    const {
      query: { when: date },
    } = useRouter();

    return (
      <Grid
        id={product.id}
        className="measure-this"
        alignContent={hideDetails ? 'normal' : 'start'}
        gridRowGap={0}
        gridColumnGap={0}
        fontSize={sizes.wrapperFontSize}
        css={theme => css`
          position: relative;
          height: 100%;
          /* IOS 12is blank scroll mitigation*/
          -webkit-transform: translate3d(0, 0, 0);
          -webkit-perspective: 1000;

          ${fadeAnimations};

          .image-wrapper {
            box-shadow: ${theme.shadows.products};
          }

          &:hover {
            .highlightOnHover {
              color: ${theme.colors.accent} !important;
            }

            .image-wrapper {
              box-shadow: ${theme.shadows.productsHover};
            }

            .savings-badge {
              background-color: ${theme.colors.accent};
            }

            .soldOutWrapper {
              animation-name: fadeOut;
            }
          }

          .soldOutWrapper {
            ${soldOutWrapperStyle}
            animation-duration: 500ms;
            animation-fill-mode: both;
            animation-name: fadeIn;
          }

          ${isLimitedTimeDeal &&
          css`
            @media ${theme.mediaQueries.tabletUp} {
              .image-wrapper {
                box-shadow: none;
              }
              &:hover {
                .image-wrapper {
                  box-shadow: none;
                }
              }
            }
          `}
        `}
      >
        {noLink && (
          <GalleryProductImage
            product={product}
            sizes={sizes}
            hideDetails={hideDetails}
            isLimitedTimeDeal={isLimitedTimeDeal}
            {...restProps}
          />
        )}

        {!noLink && (
          <GalleryProductLink
            product={product}
            date={date}
            setScrollPosition
            css={css`
              backface-visibility: hidden;
              ${fadeAnimations};
            `}
          >
            <GalleryProductImage
              product={product}
              sizes={sizes}
              hideDetails={hideDetails}
              isLimitedTimeDeal={isLimitedTimeDeal}
              {...restProps}
            />
          </GalleryProductLink>
        )}

        {!hideDetails && (
          <>
            <GalleryProductLink
              product={product}
              date={date}
              setScrollPosition
              css={css`
                overflow: hidden;
              `}
            >
              <GalleryProductTitles
                product={product}
                ellipsisBrand={ellipsisBrand}
              />
            </GalleryProductLink>

            <Grid
              gridTemplateColumns="1fr auto"
              alignItems="flex-start"
              justifyContent="space-between"
              gridColumnGap={0}
              gridRowGap={0}
              mb={2}
              css={css`
                overflow: visible;
              `}
            >
              <GalleryProductPricing product={product} sizes={sizes} />
              {!disableActions && (
                <GalleryProductActions
                  product={product}
                  openQuickAddToCart={openQuickAddToCart}
                />
              )}
            </Grid>

            <GalleryProductClearancePills product={product} />
          </>
        )}
      </Grid>
    );
  }
);

const Wrapper = ({
  isSkeleton,
  ...props
}: GalleryProductProps & { isSkeleton?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [productHeight, setProductHeight] = useState(0);
  const [observedHeight, setObservedHeight] = useState(0);

  useEffect(() => {
    !isSkeleton && setProductHeight(observedHeight);
  }, [isSkeleton, observedHeight]);

  const resizeCallback = useCallback((entry: ResizeObserverEntry) => {
    entry.contentRect && setObservedHeight(entry.contentRect.height);
  }, []);

  useResized({
    ref,
    callback: resizeCallback,
  });

  return (
    <section ref={ref}>
      {isSkeleton ? (
        <GalleryProductSkeleton height={productHeight} {...props} />
      ) : (
        <GalleryProduct {...props} />
      )}
    </section>
  );
};

export default Wrapper;
