import { css } from '@emotion/react';
import { Plus as AddToCartIcon } from 'react-feather';

const Icon = ({ size, className }: { size: number[]; className?: string }) => (
  <AddToCartIcon
    className={className}
    css={theme => css`
      width: ${size[0]}px;
      height: ${size[0]}px;

      ${size[1] &&
      css`
        @media ${theme.mediaQueries.tabletUp} {
          width: ${size[1]}px;
          height: ${size[1]}px;
        }
      `}

      ${size[2] &&
      css`
        @media ${theme.mediaQueries.tabletUp} {
          width: ${size[2]}px;
          height: ${size[2]}px;
        }
      `}
      
      transition: transform 0.5s ease-in-out;

      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      &.loading {
        animation-name: pulse;
      }

      @keyframes pulse {
        from {
          transform: scale3d(1, 1, 1);
        }

        50% {
          transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
          transform: scale3d(1, 1, 1);
        }
      }
    `}
  />
);

export default Icon;
